var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.siteSettings
    ? _c(
        "section",
        {
          staticClass: "hero is-fullheight",
          staticStyle: { "background-color": "#F1F1F1" },
        },
        [
          _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
          _c("div", { staticClass: "hero-body" }, [
            _c(
              "div",
              { staticClass: "container", staticStyle: { width: "99vw" } },
              [
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column" }),
                  _c("div", { staticClass: "column" }, [
                    !_vm.missingUserId
                      ? _c("div", { attrs: { id: "changePassword" } }, [
                          _c("div", [
                            _c("figure", { staticClass: "has-text-centered" }, [
                              _c("img", {
                                staticStyle: { "max-width": "250px" },
                                attrs: { src: _vm.activeSite.logoUrl },
                              }),
                            ]),
                            _c(
                              "form",
                              {
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.changePassword.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "field" }, [
                                  _c(
                                    "label",
                                    { staticClass: "label is-size-7" },
                                    [_vm._v("New Password")]
                                  ),
                                  _c("div", { staticClass: "control" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.input.newPassword,
                                          expression: "input.newPassword",
                                        },
                                      ],
                                      staticClass: "input",
                                      attrs: {
                                        type: "password",
                                        name: "password",
                                      },
                                      domProps: {
                                        value: _vm.input.newPassword,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.input,
                                            "newPassword",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "is-size-7",
                                    staticStyle: {
                                      padding: "10px 0px 20px 40px",
                                    },
                                  },
                                  _vm._l(
                                    _vm.passwordValidation,
                                    function (item) {
                                      return _c(
                                        "div",
                                        { key: item.msg + item.isValid },
                                        [
                                          _c("i", {
                                            class: item.isValid
                                              ? "far fa-check-circle has-text-accent"
                                              : "fas fa-times has-text-danger",
                                          }),
                                          _vm._v(
                                            " : " + _vm._s(item.msg) + " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _c("div", { staticClass: "field" }, [
                                  _c(
                                    "label",
                                    { staticClass: "label is-size-7" },
                                    [_vm._v("Confirm Password")]
                                  ),
                                  _c("div", { staticClass: "control" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.input.password2,
                                          expression: "input.password2",
                                        },
                                      ],
                                      staticClass: "input",
                                      attrs: {
                                        type: "password",
                                        name: "password",
                                      },
                                      domProps: { value: _vm.input.password2 },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.input,
                                            "password2",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _c("div", { staticClass: "is-clearfix" }, [
                                  _c(
                                    "div",
                                    { staticClass: "field is-pulled-right" },
                                    [
                                      _c("div", { staticClass: "control" }, [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "button is-accent",
                                            on: { click: _vm.changePassword },
                                          },
                                          [_vm._v("Change Password")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ])
                      : _c("div", [
                          _c("b", [_vm._v("Missing required URL data.")]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            " Please copy and paste the whole link or click the button in the email. "
                          ),
                        ]),
                  ]),
                  _c("div", { staticClass: "column" }),
                ]),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }